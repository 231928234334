import React, { FC } from "react";
import { Text as ChakraText, Button, Flex } from "@chakra-ui/react";
import { Icon } from "~/foundation/Components/Icon";
import { useTranslation } from "~/foundation/Dictionary";
import { MediaDictionary } from "../dictionary";
import { generateZipWithFilesFromUrls, saveAs } from "~/foundation/Zip";
import { SerializedItem, useSitecoreContext } from "~/foundation/Jss";
import { DocumentListItem, DocumentListSection } from "../generated-types";
import { format as formatDate } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { arSA } from "date-fns/locale";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type DocumentListDownloadProps = {
	item: SerializedItem<DocumentListSection, DocumentListItem>,
}

export const DocumentListDownload: FC<DocumentListDownloadProps> = ({ item }) => {
	const [t] = useTranslation<MediaDictionary>();
	const { sitecoreContext } = useSitecoreContext();
	const now = new Date();

	const downloadFiles = async (urls: string[]) => {
		const blob = await generateZipWithFilesFromUrls("blob", urls);

		const date = formatDate(
			utcToZonedTime(now, localTimeZone),
			sitecoreContext.custom.settings.dateFormat || "MMMM dd, yyyy",
			{ locale: sitecoreContext.custom.settings.isRtl ? arSA : undefined }
		);

		const downloadLabel = `${item.name}, ${urls.length} ${urls.length > 1 ? t("general.labels.files") : t("general.labels.file")} (${t("general.labels.from")} ${sitecoreContext.route?.displayName}, ${sitecoreContext.custom.absoluteUrl.substring("https://".length)}, ${date}).zip`;

		if (blob) {
			await saveAs(blob, downloadLabel);
		}
	};

	const downloadListSection = async () => {
		const urls = item.children.filter(x => x.fields?.asset?.value.isMediaLink).map(x => x.fields?.asset?.value.href).filter(Boolean) as string[];
		await downloadFiles(urls);
	};

	return (
		<Flex
			justifyContent="flex-end"
			alignItems="center"
			color="primary.aramcoLinkBlue"
			py={["4", null, "6"]}
			borderTopColor="primary.lightGrey"
			borderTopWidth="1px">
			<Button
				onClick={downloadListSection}
				variant="primary"
				size="md"
			>
				<ChakraText variant="footerBody">{t("media.documentList.downloadZipFile")}</ChakraText>
				<Flex className="icon-wrapper" ms="5" color="primary.aramcoLinkBlue">
					<Icon
						variant="Download"
						w="icons.md"
					/>
				</Flex>
			</Button>
		</Flex>
	)
}