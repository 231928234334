export const constants = {
	documentList: {
		templateIds: {
			documentListTab: "b3190ff6-afda-40e5-b8c6-d53e41983bd9",
			documentListSection: "321e0eed-cf7f-42c4-8abe-20c00ea9a51b",
			documentListItem: "a81ce46b-eda4-46ea-86b2-70b3e30e3238",
			documentListText: "619afc6d-fceb-4c98-b3d4-ce40bbeac5fe",
		}
	},
	scrollStory: {
		templateIds: {
			intro: "21a74017-6965-4dd1-85cb-fc6de515aa2d",
			outro: "f83023e2-765f-471e-8e81-cb574c5f0b45",
			sections: "eafaea86-f7b8-43d4-b541-6f9cd82997b7",
		}
	}
}