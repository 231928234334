import React, { FC, useContext } from "react";
import { SerializedItem } from "~/foundation/Jss";
import { DocumentListTab } from "../generated-types";
import { Accordion as ChakraAccordion, AccordionButton, AccordionItem, AccordionPanel, Box, Text as ChakraText } from "@chakra-ui/react";
import { Icon } from "~/foundation/Components/Icon";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type DocumentListTabProps = {
	item: SerializedItem<DocumentListTab, never>,
	children?: React.ReactNode
}

export const RenderDocumentListTab: FC<DocumentListTabProps> = ({ children, item }) => {
	const [t] = useTranslation<GlobalDictionary>();
	const [theme] = useContext(ColorThemeContext);

	return (
		<>
			<AccordionItem
				color="primary.text"
			>
				{({ isExpanded }) => (
					<>
						<Box borderRadius="lg" px={["4", null, "8"]} mb="4" bg={theme.name === "Default" ? "themeBackgroundColors.coldGreen" : "primary.white"}>
							<h3>
								<AccordionButton
									py={["4", null, "1.375rem"]}
									justifyContent="space-between"
									aria-label={isExpanded ? t("general.labels.close") : t("general.labels.open")}
									title={isExpanded ? t("general.labels.close") : t("general.labels.open")}>
									<Box textAlign="left">
										<ChakraText as="span" size="xs" flex="1" textAlign="left">{item.fields?.title?.value}</ChakraText>
									</Box>
									<Icon
										variant={isExpanded ? "Up" : "Down"}
										w="icons.lg"
										flexShrink="0"
										marginInlineEnd="1"
										color="primary.aramcoLinkBlue"
										ms={["4", null, "12"]}
									/>
								</AccordionButton>
							</h3>
						</Box>
						<AccordionPanel>
							<ChakraAccordion allowMultiple defaultIndex={[0]}>
								{children}
							</ChakraAccordion>
						</AccordionPanel>

					</>
				)}
			</AccordionItem>
		</>
	);
}