import React, { FC, useContext } from "react";
import { SerializedItem, useSitecoreContext } from "~/foundation/Jss";
import { SuitcaseContext } from "~/foundation/Suitcase";
import { DocumentListItem } from "../generated-types";
import { Text as ChakraText, Flex, Button as ChakraButton, Box, Spacer } from "@chakra-ui/react";
import { Icon } from "~/foundation/Components/Icon";
import { useTranslation } from "~/foundation/Dictionary";
import { MediaDictionary } from "../dictionary";
import { getLinkVariants } from "~/foundation/Theme/components/Link";
import { ChakraRouterLink } from "~/foundation/Components/Link/ChakraRouterLink";
import { bytesToSize } from "~/foundation/Utils/byteSizeConverter";
import { CustomTooltip } from "~/foundation/Components/CustomTooltip";
import { Separator } from "~/foundation/Components/Separator";
import { dateFormatter } from "~/foundation/Utils/dateFormatter";

type RenderDocumentListItemProps = {
	item: SerializedItem<DocumentListItem, never>;
	index: number;
	total: number;
	group?: string;
	compactVersion?: boolean;
}

export const RenderDocumentListItem: FC<RenderDocumentListItemProps> = ({ item, index, total, group, compactVersion }) => {
	const [suitcase, dispatch] = useContext(SuitcaseContext);
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [t] = useTranslation<MediaDictionary>();
	const title = item.fields?.title?.value;
	const asset = item.fields?.asset?.value;
	if (!asset || !asset.href) {
		return <></>;
	}

	const linkVariants = getLinkVariants(isRtl);

	const trackingAttributes = {
		"data-tracking-type": "visibility",
		"data-tracking-id": "documents_list",
		"data-tracking-data": JSON.stringify({
			type: "documents list",
			group,
			text: title,
			index: index + 1,
			count: total,
			url: item.fields?.asset?.value.href,
			file_type: item.fields?.asset?.value.isMediaLink ? item.fields?.asset?.value.mediaExtension : "external",
		})
	};

	return (
		<>
			{asset.isMediaLink ?
				<Flex
					alignSelf="stretch"
					py={["4", null, "6"]}
					borderTopColor="primary.lightGrey"
					borderTopWidth="1px"
					{...trackingAttributes}
				>
					<ChakraRouterLink
						variant="unstyled"
						isExternal
						display="flex"
						justifyContent="space-between"
						flexGrow="1"
						alignItems="center"
						color="primary.aramcoLinkBlue"
						_hover={{ textDecoration: "none" }}
						{...(!sitecoreContext.pageEditing && {
							to: asset.href,
						})}
					>
						<Box display={["block", null, "flex"]} alignItems="center" flexGrow="1">
							<ChakraText
								size="body"
								fontSize={['sm', null, null, 'body']}
								sx={{ ...linkVariants.tertiary }}
							>
								{title}
							</ChakraText>
							{!compactVersion &&
								<>
									<Spacer />
									<Flex
										flexShrink="0"
										justifyContent={["flex-start", null, null, "flex-end"]}
										ps={["0", null, null, "5"]}
										pe={["0", null, null, "3.25rem"]}
										textAlign={["start", null, null, "end"]}
										color="primary.text"
									>
										{(item.fields?.publishDate?.value && item.fields?.publishDate?.value !== "") &&
									<>
										<ChakraText variant="mediaSpec">{dateFormatter(new Date(item.fields.publishDate.value), isRtl)}</ChakraText>
										<ChakraText
											variant="mediaSpec"
											px="2"
											pe="1.5"><Separator h=".5rem" /></ChakraText>
									</>
										}
										{asset.mediaExtension &&
									<>
										<ChakraText variant="mediaSpec" sx={{ direction: "ltr" }}>{!isRtl && "."}{asset.mediaExtension}{isRtl && "."}</ChakraText>
										<ChakraText
											variant="mediaSpec"
											px="2"
											pe="1.5"><Separator h=".5rem" /></ChakraText>
									</>
										}
										{asset.mediaSize &&
									<ChakraText variant="mediaSpec">{bytesToSize(asset.mediaSize)}</ChakraText>
										}
									</Flex>
								</>
							}
						</Box>
						<Box ms="5" display="inline-flex">
							<CustomTooltip label={t("general.labels.downloadFile")}>
								<Box display="inline-flex">
									<Icon
										variant="Download"
										w="icons.lg"
										color="primary.aramcoLinkBlue"
									/>
								</Box>
							</CustomTooltip>
						</Box>
					</ChakraRouterLink>
					<Box ms="6" me="1" display="inline-flex">
						<CustomTooltip label={t("general.suitcase.addToSuitcase")}>
							<ChakraButton
								onClick={() => dispatch({ type: "ADD", payload: { name: title, url: asset.href!, type: asset.mediaExtension?.toUpperCase(), size: bytesToSize(asset.mediaSize) } })}
								aria-label={t("general.suitcase.addToSuitcase")}
								title={t("general.suitcase.addToSuitcase")}>
								<Icon
									variant={suitcase.some(x => x.url === asset?.href) ? "Checkmark" : "Plus"}
									w="icons.lg"
									color="primary.aramcoLinkBlue"
								/>
							</ChakraButton>
						</CustomTooltip>
					</Box>
				</Flex>
				:
				<ChakraRouterLink
					variant="unstyled"
					isExternal
					display="flex"
					color="primary.aramcoLinkBlue"
					alignSelf="stretch"
					py={["4", null, "6"]}
					borderTopColor="primary.lightGrey"
					borderTopWidth="1px"
					{...(!sitecoreContext.pageEditing && {
						to: asset.href,
					})}
					{...trackingAttributes}
				>

					<Flex
						flexWrap={["wrap", null, null, "nowrap"]}
						flexGrow="1"
						gap="5"
						justifyContent="space-between"
						alignItems="center"
						pe={["0", null, null, "14"]}>
						<Box display={["block", null, "flex"]} alignItems="center" justifyContent="space-between" flexGrow="1">
							<ChakraText
								size="body"
								fontSize={['sm', null, null, 'body']}
								sx={{ ...linkVariants.tertiary }}
							>
								{title}
							</ChakraText>
							<ChakraText
								justifyContent={["flex-start", null, null, "flex-end"]}
								flexShrink="0"
								textAlign={["start", null, null, "end"]}
								color="primary.aramcoGrey"
								variant="mediaSpec">
								{t("media.documentList.externalFile")}
							</ChakraText>
						</Box>
					</Flex>
					<Icon
						variant="ExternalLink"
						w="icons.lg"
						ms={["4.252rem", null, "16"]}
						me="1"
						color="primary.aramcoLinkBlue"
					/>
				</ChakraRouterLink>
			}
		</>

	);
};