import React, { FC } from "react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { SerializedItem } from "~/foundation/Jss";
import { constants } from "../constants";
import {
	ColumnType,
	DocumentListItem,
	DocumentListProps,
	DocumentListSection,
	DocumentListTab
} from "../generated-types";
import { RenderDocumentListItem } from "./RenderDocumentListItem";
import { RenderDocumentListSection } from "./RenderDocumentListSection";
import { RenderDocumentListTab } from "./RenderDocumentListTab";
import {
	Accordion as ChakraAccordion, Flex
} from "@chakra-ui/react";
import { themable } from "~/foundation/Theme";

const DocumentList: FC<DocumentListProps> = ({ fields, rendering, params }) => {

	if (rendering.customData?.columnType === ColumnType.OneColumn || rendering.customData?.columnType === ColumnType.TwoColumn) {
		return (
			<ContentWrapper py={["0", null]} pb={["12", null, "16"]}>
				<RenderDocumentList fields={fields} rendering={rendering} params={params} />
			</ContentWrapper>
		)
	}

	if (rendering.customData?.columnType === ColumnType.ChapterTeaser) {
		return <RenderDocumentList fields={fields} rendering={rendering} params={params} />
	}

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper
				py={["0", null]}
				px={['4', null, "16", null, '32']}>
				<RenderDocumentList fields={fields} rendering={rendering} params={params} />
			</ContentWrapper>
		</Ribbon>
	);
};

const RenderDocumentList: FC<DocumentListProps> = ({ rendering }) => {
	const onlyOneChild = rendering.children?.length === 1;
	const firstChild = rendering.children && rendering.children[0];
	const noBg = rendering.customData?.columnType === ColumnType.ChapterTeaser;
	const firstChildIsSection = firstChild?.templateId === constants.documentList.templateIds.documentListSection;

	let section = firstChildIsSection && onlyOneChild && firstChild;

	if (!section) {
		const firstChildIsTab = firstChild?.templateId === constants.documentList.templateIds.documentListTab;
		const tabHasOneSection = firstChild?.children.length === 1 && firstChild?.children[0].templateId === constants.documentList.templateIds.documentListSection;
		if (onlyOneChild && firstChildIsTab && tabHasOneSection) {
			section = firstChild.children[0];
		}
	}

	return (
		<>
			{rendering.children && (
				<>
					{section
						? (
							<RenderDocumentListSection item={rendering.children[0]} isAccordion={false} noBg={noBg}>
								<RecursiveRenderer items={section.children} noBg={noBg} />
							</RenderDocumentListSection>
						)
						: (
							<ChakraAccordion allowToggle={true} defaultIndex={[0]}>
								<Flex flexDir="column">
									<RecursiveRenderer items={rendering.children} noBg={noBg}/>
								</Flex>
							</ChakraAccordion>
						)
					}
				</>
			)}
		</>
	)
};

type RecursiveRendererProps = {
	items: SerializedItem<unknown, unknown>[];
	group?: string;
	noBg: boolean;
	compactVersion?: boolean;
}

export const RecursiveRenderer: FC<RecursiveRendererProps> = ({ items, group, noBg, compactVersion }) => {
	return (
		<>
			{items.map((child: SerializedItem<unknown, unknown>, index) => {

				if (child.templateId === constants.documentList.templateIds.documentListTab && child.children.length > 0) {
					return (
						<RenderDocumentListTab key={child.id} item={child as SerializedItem<DocumentListTab, never>}>
							<RecursiveRenderer items={child.children} noBg={noBg} />
						</RenderDocumentListTab>
					)
				}

				if (child.templateId === constants.documentList.templateIds.documentListSection) {
					const item = child as SerializedItem<DocumentListSection, never>;
					return (
						<RenderDocumentListSection key={child.id} isAccordion={true} item={item} noBg={noBg} compactVersion={compactVersion}>
							<RecursiveRenderer items={child.children} group={item.fields?.title?.value} noBg={noBg} />
						</RenderDocumentListSection>
					)
				}

				if (child.templateId === constants.documentList.templateIds.documentListItem) {
					return (
						<RenderDocumentListItem key={child.id} item={child as SerializedItem<DocumentListItem, never>} index={index} total={items.length} group={group} compactVersion={compactVersion} />
					)
				}

				return <React.Fragment key={child.id} />;

			})}
		</>
	);
}

export default themable()(DocumentList);